<template>
  <div class="setting">
    <!-- 返回0 -->
    <div class="goback" @click="settype">
      <i class="el-icon-arrow-left"></i>
      <div>返回</div>
    </div>
    <!-- 保存 -->
    <div class="information">
      <div class="informationleftbox">
        <div class="border"></div>
        <div class="text">基本信息</div>
      </div>
      <div class="informationright" @click="setruleForm">
        <div class="textname">保存</div>
      </div>
    </div>
    <div class="form">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="头  像">
          <div style="cursor: pointer" @click="setdialogVisible">
            <el-avatar
              :key="ruleForm.Avatar"
              :src="ruleForm.Avatar"
            ></el-avatar>
          </div>
        </el-form-item>
        <el-form-item label="昵  称" prop="name">
          <el-input v-model="ruleForm.Name"></el-input>
        </el-form-item>
        <el-form-item label="性  别" prop="resource">
          <el-radio-group v-model="ruleForm.Gender">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生  日" prop="resource">
          <span>
            <el-date-picker
              v-model="ruleForm.Birthday"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </span>
        </el-form-item>
        <el-form-item label="家  乡" prop="resource">
          <span>
            <el-cascader
              v-model="ruleForm.Area"
              :options="options"
              :placeholder="ruleForm.Area"
              ref="cascaderAddr"
              :props="{ expandTrigger: 'hover' }"
              @change="handleChange"
            ></el-cascader>
          </span>
        </el-form-item>
      </el-form>
    </div>
    <!-- 保存 -->
    <div class="information">
      <div class="informationleftbox">
        <div class="border"></div>
        <div class="text">个性签名</div>
      </div>
      <div class="informationright">
        <!-- <div class="textname"></div> -->
      </div>
    </div>
    <div class="textarea">
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入内容"
        v-model="ruleForm.PersonalSignature"
        :autosize="false"
      >
      </el-input>
    </div>
    <el-dialog title="更换头像" :visible.sync="dialogVisible" width="30%">
      <el-divider></el-divider>
      <div class="dialogimage">
        <div class="left">
          <el-image
            style="width: 280px; height: 280px; border-radius: 50%"
            :src="dialogimage"
            fit="cover"
          ></el-image>
        </div>
        <div class="right">
          <div class="top">
            <div class="title">预览</div>
            <el-image
              style="width: 100px; height: 100px; border-radius: 50%"
              :src="dialogimage"
              fit="cover"
            ></el-image>
            <div class="number">100 x 100</div>
            <el-image
              style="width: 50px; height: 50px; border-radius: 50%"
              :src="dialogimage"
              fit="cover"
            ></el-image>
            <div class="number">50 x 50</div>
          </div>
          <div class="bottom">
            <el-upload
              class="upload-demo"
              action="https://up-z2.qiniup.com/"
              :show-file-list="false"
              :on-success="newhandleAvatarSuccess"
              :data="postData"
            >
              <div class="upload">上传</div>
            </el-upload>
            <div class="button" @click="succdialogVisible">确定</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import city from "../../../static/js/city";
export default {
  props: {
    type: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dialogimage: "",
      ruleForm: {
        Name: "",
        Gender: "",
        Birthday: "",
        Area: "",
        PersonalSignature: "",
        Avatar: "",
      },
      postData: {},
      dialogVisible: false, //头像弹框
      textarea: "",
      rules: {
        Name: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
      },
      text: "",
      options: [], //省市区
      value: "",
      form: {},
      postData: {
        token: "", //七牛云上传token
        region: "SCN",
      },
      imgkey: "",
    };
  },
  created() {
    this.getuserinfo();
    this.options = city;
    this.qny_think();
  },
  methods: {
    succdialogVisible() {
      if (this.imgkey != "") {
        this.ruleForm.Avatar = this.dialogimage;
      }
      this.dialogVisible = !this.dialogVisible;
    },
    // 更换头像
    setdialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    handleChange() {
      this.text = "";
      let array = this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels;
      for (let index = 0; index < array.length; index++) {
        this.text = this.text + array[index];
      }
    },
    newhandleAvatarSuccess(res, file) {
      this.imgkey = res.key;
      this.dialogimage = `https://adn.xsdyiwu.com/${res.key}`;
    },
    // 七牛云上传token
    qny_think() {
      this.$api.common
        .qny_think({ token: window.localStorage.getItem("token") })
        .then((res) => {
          if (res.code == 200) {
            this.postData.token = res.data.token;
          }
        });
    },
    // 保存
    setruleForm() {
      let obj = { ...this.ruleForm };
      if (this.text != "") {
        obj.Area = this.text;
      }
      if (this.imgkey != "") {
        obj.Avatar = this.imgkey;
      }
      if (obj.Avatar.indexOf("https://adn.xsdyiwu.com/") != -1) {
        obj.Avatar = obj.Avatar.slice(24);
      }
      window.localStorage.setItem("Avatar", this.ruleForm.Avatar);
      this.$api.common.info_update(obj).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getuserinfo();
          this.$emit("getuserinfo");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取个人信息
    getuserinfo() {
      this.$api.common
        .apimemberInfo({ token: window.localStorage.getItem("token") })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.ruleForm = res.data;
            this.dialogimage = res.data.Avatar;
            this.ruleForm["token"] = window.localStorage.getItem("token");
          }
        });
    },
    settype() {
      this.$emit("settype");
    },
  },
};
</script>

<style lang='less' scoped>
.dialogimage {
  width: 100%;
  display: flex;

  .left {
    width: 318px;
  }
  .right {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .top {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title {
        width: 32px;
        font-size: 16px;
        font-family: NotoSansHans;
        font-weight: normal;
        color: #818181;
        margin-bottom: 28px;
      }
      .number {
        font-size: 14px;
        font-family: NotoSansHans;
        font-weight: 300;
        color: #818181;
        margin-top: 5px;
        margin-bottom: 20px;
      }
    }
    .bottom {
      width: 100%;
      display: flex;
      align-items: center;

      justify-content: space-around;
      .upload {
        font-size: 16px;
        font-family: NotoSansHans;
        font-weight: normal;
        color: #e6bf84;
        cursor: pointer;
      }
      .button {
        width: 68px;
        height: 34px;
        background: #e6bf84;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: NotoSansHans;
        font-weight: normal;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
/deep/.el-select .el-input__inner {
  width: 100px;
}
/deep/.el-divider--horizontal {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}
/deep/.el-divider--horizontal {
  background-color: #f8f8f8;
}
/deep/.el-input__inner {
  &::placeholder {
    color: #606266;
    font-size: 16px;
  }
}
.setting {
  width: 963px;
  min-height: 670px;
  background: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(172, 148, 113, 0.3);
  border-radius: 10px;
  margin-top: -98px;
  box-sizing: border-box;
  padding: 30px;
  .goback {
    width: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-family: NotoSansHans;
    font-weight: 300;
    color: #818181;
    div {
      margin-left: 10px;
    }
  }
  .information {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .informationleftbox {
      display: flex;
      .border {
        width: 4px;
        height: 23px;
        background: #e6bf84;
        border-radius: 2px;
      }
      .text {
        margin-left: 14px;
        font-size: 18px;
        font-family: NotoSansHans;
        font-weight: normal;
        color: #e6bf84;
      }
    }
    .informationright {
      .textname {
        width: 60px;
        height: 35px;
        background-color: #e6bf84;
        border-radius: 5px;
        cursor: pointer;
        font-size: 18px;
        font-family: NotoSansHans;
        font-weight: normal;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .form {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 80px;
    .birthday {
      width: 100px;
      margin-left: 20px;
    }
  }
  .textarea {
    width: 693px;
    min-height: 127px;
    margin: auto;
    border-radius: 10px;
  }
}
</style>
