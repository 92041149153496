<template>
  <div class="userinfo">
    <div class="personaldata">
      <div class="Avatar">
        <img :src="mine.MemberData.Avatar" alt="" />
        <div class="Grade" v-if="mine.MemberData.Gender == '女'">
          {{ mine.MemberData.Grade }}
        </div>
        <div class="Grademan" v-if="mine.MemberData.Gender == '男'">
          {{ mine.MemberData.Grade }}
        </div>
      </div>
      <div class="name">
        <span>{{ mine.MemberData.Name }}</span>
        <!-- <img src="../../static/img/lvip.png" alt="" /> -->
        <img
          class="lvip"
          v-if="mine.MemberData.BigV == 1"
          src="../../static/img/lvip.png"
          alt=""
        />
        <img
          class="lvip"
          v-if="mine.MemberData.BigV == 2"
          src="../../static/img/hvip (1).png"
          alt=""
        />
      </div>
      <div class="fame"><p>{{ mine.MemberData.PersonalSignature }}</p></div>
      <div class="detailed">
        <div class="box">
          <div>{{ mine.MemberData.AttentionCount }}</div>
          <div>关注</div>
        </div>
        <div class="Divider"></div>
        <div class="box">
          <div>{{ mine.MemberData.FansCount }}</div>
          <div>粉丝</div>
        </div>
        <div class="Divider"></div>
        <div class="box">
          <div>{{ mine.MemberData.FindCount }}</div>
          <div>帖子</div>
        </div>
      </div>
      <div class="setting" @click="settype">
        <i class="el-icon-setting"></i>
      </div>
    </div>
    <div class="Signin">
      <div class="Signinbox">
        <div class="qiandao" @click="setSigntype">
          <img src="../../static/img/qiandao (1).png" alt="" />
          <span>签到</span>
        </div>
        <div class="border"></div>
        <div class="day">
          已签<span>{{ talnum }}</span
          >天
        </div>
      </div>
      <div class="tiezi" v-if="type == 1">
        <div class="category">
          <div :class="index == 0 ? 'action' : 'disted'" @click="setindex(0)">
            帖子（{{ mine.MemberData.FindCount }}）
          </div>
          <div :class="index == 1 ? 'action' : 'disted'" @click="setindex(1)">
            干货（{{ mine.MemberData.FindDryGoodsCount }}）
          </div>
        </div>
        <div class="paixu">
          <!-- <el-dropdown>
            <span class="el-dropdown-link">
              排序<i class="el-icon-sort"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>点赞排序</el-dropdown-item>
              <el-dropdown-item>游览排序</el-dropdown-item>
              <el-dropdown-item>回复排序</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
    </div>
    <div class="achievement">
      <div class="leftbox">
        <div class="lefttop">
          <Achievement />
        </div>
        <div class="leftbottom">
          <Recommended />
        </div>
      </div>
      <div class="rightlistbox" v-if="type">
        <List :MemberCode="form.MemberCode" v-if="index == 0" />
        <Newlist :MemberCode="form.MemberCode" v-if="index == 1" />
      </div>
      <Setting
        v-on:settype="settype"
        @getuserinfo="getuserinfo"
        :type="type"
        v-if="!type"
      />
    </div>
  </div>
</template>

<script>
import Achievement from "./common/achievement.vue";
import List from "./common/list.vue";
import Newlist from "./common/listGH.vue";
import Recommended from "./common/Recommended.vue";
import Setting from "./common/setting.vue";
export default {
  data() {
    return {
      index: 0,
      type: true, //个人设置状态
      Signtype: false, //签到状态
      mine: {
        MemberData: {},
      },
      form: {
        MemberCode: "",
      },
      talnum: 0,
    };
  },
  components: {
    Achievement,
    List,
    Recommended,
    Newlist,
    Setting,
  },
  created() {
    this.getuserinfo();
    this.gettotalnum();
    this.form.MemberCode = JSON.parse(
      window.localStorage.getItem("userinfo")
    ).MemberCode;
  },
  methods: {
    // 查询签到天数
    gettotalnum() {
      this.$api.common
        .index({ token: window.localStorage.getItem("token") })
        .then((res) => {
          if (res.code == 200) {
            this.talnum = res.data.TotalNum;
          }
        });
    },
    // 查询个人信息
    getuserinfo() {
      this.$api.common
        .MemberInfo({
          MemberCode: JSON.parse(window.localStorage.getItem("userinfo"))
            .MemberCode,
          token: window.localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.code == 200) {
            this.mine = res.data;
          }
        });
    },
    // 切换list
    setindex(index) {
      this.index = index;
    },
    // 签到
    setSigntype() {
      this.$api.common
        .Attendance({ token: window.localStorage.getItem("token") })
        .then((res) => {
          if (res.code == 200) {
            this.$store.commit("changeSigntype", true);
            this.$store.commit("changeranking", res.data.Ranking);
            this.$store.commit("changeContinuousCheckIn", res.data.TotalNum);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 打开个人设置
    settype() {
      this.type = !this.type;
    },
  },
};
</script>

<style lang='less' scoped>
@import url("./index.less");
</style>
