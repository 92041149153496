<template>
  <div class="achievementbox">
    <div class="title">获得成就</div>
    <el-divider></el-divider>
    <div class="msgbox">
      <img src="../../../static/img/zan2.png" alt="" />
      <span>获得{{ mine.WasPraised }}次点赞</span>
    </div>
    <div class="msgbox">
      <img src="../../../static/img/neirong.png" alt="" />
      <span>获得声望{{ mine.Prestige }}</span>
    </div>
    <div class="msgbox">
      <img src="../../../static/img/zhuanfa (1).png" alt="" />
      <span>获得{{ mine.Integral }}积分</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mine: {},
    };
  },
  created() {
    this.$api.common
      .index({ token: window.localStorage.getItem("token") })
      .then((res) => {
        if (res.code == 200) {
          this.mine = res.data;
        }
      });
  },
  methods: {},
};
</script>

<style lang='less' scoped>
.achievementbox {
  width: 100%;
  .title {
    font-size: 18px;
    font-family: NotoSansHans;
    font-weight: 300;
    color: #818181;
  }
  /deep/.el-divider--horizontal {
    margin: 10px 0px;
  }
  /deep/.el-divider--horizontal {
    background-color: #f8f8f8;
  }
  .msgbox {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
      width: 21px;
      height: 21px;
    }
    span {
      width: 116px;
      height: 15px;
      font-size: 14px;
      font-family: NotoSansHans;
      font-weight: 100;
      color: #818181;
      margin-left: 10px;
    }
  }
}
</style>
