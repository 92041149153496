<template>
  <div class="Recommended">
    <div class="title">推荐关注</div>
    <el-divider></el-divider>
    <div class="concerns" v-for="(item, index) in list" :key="index">
      <div class="concernsleft" @click="gotoOtherspersonal(item)">
        <img :src="item.Avatar" alt="" />
        <div class="name">{{ item.Name }}</div>
      </div>
      <div class="rightbox" v-if="item.AttentionStatus == 0">关注</div>
      <div class="newrightbox" v-if="item.AttentionStatus == 1">已关注</div>
    </div>
    <div class="newlist" @click="recommended_attention_list">换一批</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          type: 1,
        },
        {
          type: 0,
        },
        {
          type: 0,
        },
        {
          type: 1,
        },
        {
          type: 1,
        },
        {
          type: 1,
        },
      ],
    };
  },
  created() {
    this.recommended_attention_list();
  },
  methods: {
    // 去用户首页
    gotoOtherspersonal(item) {
      this.$router.push(`Otherspersonal?id=${item.MemberCode}`);
    },
    recommended_attention_list() {
      this.$api.common
        .recommended_attention_list({
          token: window.localStorage.getItem("token"),
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.list = res.data;
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
.Recommended {
  width: 100%;
  .title {
    font-size: 18px;
    font-family: NotoSansHans;
    font-weight: 300;
    color: #818181;
  }
  /deep/.el-divider--horizontal {
    margin: 10px 0px;
  }
  /deep/.el-divider--horizontal {
    background-color: #f8f8f8;
  }
  .concerns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .concernsleft {
      display: flex;
      align-items: center;

      img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        cursor: pointer;
      }
      .name {
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 5px;
        cursor: pointer;
        font-size: 14px;
        font-family: NotoSansHans;
        font-weight: 300;
        color: #818181;
      }
    }
    .rightbox {
      width: 48px;
      height: 23px;
      border: 1px solid #e6bf84;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: NotoSansHans;
      font-weight: 300;
      color: #e6bf84;
      cursor: pointer;
    }
    .newrightbox {
      width: 48px;
      height: 23px;
      background: #e6bf84;
      border: 1px solid #e6bf84;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: NotoSansHans;
      font-weight: 300;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .newlist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    font-size: 12px;
    font-family: NotoSansHans;
    font-weight: 100;
    color: #818181;
    opacity: 0.6;
    text-decoration: underline;
  }
}
</style>
