<template>
  <div class="listGH">
    <div class="list">
      <div
        class="box"
        v-for="(item, index) in list"
        :key="index"
        @click="gotoPostdetails(item)"
      >
        <div class="msg">
          <div class="image">
            <el-image
              class="elimage"
              :src="item.DryGoodsImage"
              fit="cover"
            ></el-image>
          </div>
          <div class="right">
            <div class="title">{{ item.DryGoodsTitle }}</div>
            <div class="conter">{{ item.DryGoodsContent | string }}</div>
            <div class="footer">
              <div class="left">
                <img :src="item.Avatar" alt="" />
                <span>{{ item.Name }}</span>
              </div>
              <div class="messgen">
                <span
                  ><i
                    class="el-icon-chat-dot-round"
                    style="margin-right: 5px"
                  ></i
                  >{{ item.Comment }}</span
                >
                <span>{{ item.time }}</span>
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    MemberCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text: " 明宣德青花釉里红鱼化龙高足杯明宣德青花釉里红鱼化龙高足杯明宣德青花釉里红鱼化龙高龙高足杯明宣德青花釉里红鱼化龙高足杯明宣德青花釉里红鱼化龙高足杯红鱼化龙高足",
      params: {
        token: window.localStorage.getItem("token"),
        MemberCode: "",
        LabelName: "",
        pageIndex: 1,
        pageSize: 30,
        DryGoods: 1,
      },
      list: [],
      total: 0,
    };
  },
  created() {
    this.getMemberFindList();
  },
  methods: {
    // 去话题
    gotoconversation(item) {
      this.$router.push(`conversation?topic=${item.Title}`);
    },
    getMemberFindList() {
      this.params.MemberCode = this.MemberCode;
      this.$api.common.MemberFindList(this.params).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getMemberFindList();
    },
    gotoPostdetails(item) {
      this.$router.push(`Postdetails?FindCode=${item.FindCode}`);
    },
  },
  filters: {
    string(val) {
      /* 去除富文本中的html标签 */
      /* *、+限定符都是贪婪的，因为它们会尽可能多的匹配文字，只有在它们的后面加上一个?就可以实现非贪婪或最小匹配。*/
      let val1 = val.replace(/<.+?>/g, "");

      /* 去除&nbsp; */
      val = val1.replace(/&nbsp;/gi, "");

      /* 去除空格 */
      val = val.replace(/\s/gi, "");
      if (val.length > 80) {
        let str = val.substr(0, 80);
        str = str + "...";
        return str;
      } else {
        return val;
      }
    },
  },
};
</script>

<style lang='less' scoped>
.listGH {
  width: 100%;
  /deep/.el-divider--horizontal {
    margin: 10px 0px;
  }
  /deep/.el-divider--horizontal {
    background-color: #f8f8f8;
  }
  .list {
    width: 100%;

    box-sizing: border-box;
    padding: 20px 40px;

    .box {
      width: 100%;

      .msg {
        width: 100%;
        display: flex;
        // align-items: center;

        .elimage {
          width: 166px;
          height: 142px;
          border-radius: 10px;
          cursor: pointer;
        }

        .right {
          width: 100%;
          margin-left: 20px;
          overflow: hidden;

          .title {
            font-size: 16px;
            font-family: NotoSansHans;
            font-weight: normal;
            color: #818181;
            margin-top: 5px;
            cursor: pointer;
          }

          .conter {
            margin-top: 16px;
            font-size: 14px;
            font-family: NotoSansHans;
            font-weight: 100;
            color: #818181;
            cursor: pointer;
            line-height: 26px;
          }

          .footer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            .left {
              display: flex;
              align-items: center;

              img {
                width: 28px;
                height: 27px;
                border-radius: 50%;
                cursor: pointer;
              }

              span {
                margin-left: 5px;
                font-size: 20px;
                font-family: NotoSansHans;
                font-weight: 400;
                color: #818181;
                cursor: pointer;
              }
            }

            .messgen {
              width: 200px;
              display: flex;
              align-items: center;
              justify-content: space-around;
              font-size: 14px;
              font-family: NotoSansHans;
              font-weight: 300;
              color: #818181;
              opacity: 0.6;
            }
          }
        }
      }
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #e6bf84 !important;
    }

    .pagination {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  }
}
</style>
